/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/reference/config-files/gatsby-browser/
 */

// You can delete this file if you're not using it
require("./src/styles/tailwindGlobal.css")
require("./src/styles/vendor/font-awesome.css")
require("./src/styles/fonts.css")
require("./src/styles/base.css")
require("./src/styles/animations.css")
require("./src/styles/buttons.css")
require("./src/styles/components.css")
require("./src/styles/utility.css")
require("./src/styles/Block.css")
require("./src/styles/editor-components.css")
const React = require("react")
const { navigate } = require("@reach/router")
const { getConsentStatus } = require("./src/utils/checkConsentCookies")
const { datadogRum } = require("@datadog/browser-rum")
const { analytics } = require("./src/utils/analytics/index.js")
const {
  PerformanceObserverContextProvider,
} = require("./src/context/perfomanceObserver.js")
const { TrackingContextProvider } = require("./src/context/tracking.js")
const { ModalContextProvider } = require("./src/context/modal.js")
const ReactDOM = require("react-dom/client")
const { isBrowser } = require("./src/utils/environment")

/*
 * Solves issues we see in production builds with the hydration of the app
 */
exports.replaceHydrateFunction = () => {
  return (element, container) => {
    const root = ReactDOM.createRoot(container)
    root.render(element)
  }
}

exports.onRouteUpdate = ({ location, prevLocation }) => {
  if (window.location === window.parent.location) {
    if (
      typeof window.drift !== "undefined" &&
      typeof window.drift.page !== "undefined"
    ) {
      window.drift.page()
    }
    if (
      typeof window.drift !== "undefined" &&
      typeof window.drift.api !== "undefined" &&
      typeof window.drift.on !== "undefined"
    ) {
      window.drift.on("conversation:firstInteraction", function (data) {
        window.drift.api.setUserAttributes({
          recent_conversationId: data.conversationId,
        })
      })
    }
  }
}

exports.onClientEntry = async () => {
  if (window.location === window.parent.location) {
    const { pathname, search } = window.location
    const directSuffix = "/direct"

    if (pathname.endsWith(directSuffix)) {
      const queryParams = new URLSearchParams(search)

      queryParams.set("direct", true)
      navigate(
        `${pathname.slice(0, -directSuffix.length)}?${queryParams.toString()}`,
        { replace: true },
      ).then(() => console.debug("direct suffix removed"))
    }

    /*
     * Initialize Datadog RUM and Amplitude if the user has given consent
     */
    if (getConsentStatus() === true) {
      datadogRum.init({
        applicationId: process.env.GATSBY_DD_APPLICATION_ID,
        clientToken: process.env.GATSBY_DD_CLIENT_TOKEN,
        site: "datadoghq.com",
        service: "checkr-marketing-application",
        env: process.env.GATSBY_CURRENT_BRANCH,
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask-user-input",
      })
      analytics().init()
      analytics().initCandidateExperience()
    }
  }
}

exports.wrapRootElement = ({ element }) => {
  return (
    <PerformanceObserverContextProvider>
      <TrackingContextProvider>
        <ModalContextProvider>{element}</ModalContextProvider>
      </TrackingContextProvider>
    </PerformanceObserverContextProvider>
  )
}
